import * as actions from "./orderActions";

import { handleActions } from "@letapp/redux-actions";

const defaultState = {
  success: false,
  isLoading: false,
  isError: false,
  error: null,
};

export default handleActions(
  {
    [actions.createOrder.start]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [actions.createOrder.success]: (state) => ({
      ...state,
      isLoading: false,
      success: true,
    }),
    [actions.createOrder.error]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      error: action.payload,
      success: false,
    }),
    [actions.clearSuccess.start]: (state) => ({
      ...state,
      success: null,
    }),
    [actions.clearErr.start]: (state) => ({
      ...state,
      isError: false,
      error: null,
    }),
  },
  defaultState
);
