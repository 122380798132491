import { createAction, createAsyncActions } from "@letapp/redux-actions";

export const fetchLast = createAsyncActions("product/FETCH_LAST");

export const fetchDiscount = createAsyncActions("product/FETCH_DISCOUNT");

export const fetchCategory = createAsyncActions("product/FETCH_CATEGORY");

export const fetchBrand = createAction("product/FETCH_BRAND");

export const fetchDetail = createAsyncActions("product/FETCH_DETAIL");
