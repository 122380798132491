import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { Spiner } from "../components/Other/Spiner";
import { routes } from "./routes";

const Contact = React.lazy(() => import("../scenes/Contact/Contact"));
const Category = React.lazy(() => import("../scenes/Category/Category"));
const Brand = React.lazy(() => import("../scenes/Brand/Brand"));
const Cart = React.lazy(() => import("../scenes/Cart/Cart"));
const Delivery = React.lazy(() => import("../scenes/Delivery/Delivery"));
const Home = React.lazy(() => import("../scenes/Home/Home"));
const Order = React.lazy(() => import("../scenes/Order/Order"));
const Product = React.lazy(() => import("../scenes/Product/Product"));
const ReturnProduct = React.lazy(() =>
  import("../scenes/Return/ReturnProduct")
);

export const AppRouter = () => {
  return (
    <Suspense fallback={<Spiner />}>
      <BrowserRouter>
        <Switch>
          <Route exact path={routes.home.path} render={() => <Home />} />
          <Route path={routes.category.path} render={() => <Category />} />
          <Route path={routes.product.path} render={() => <Product />} />
          <Route path={routes.brand.path} render={() => <Brand />} />
          <Route path={routes.cart.path} render={() => <Cart />} />
          <Route path={routes.order.path} render={() => <Order />} />
          <Route path={routes.delivery.path} render={() => <Delivery />} />
          <Route path={routes.return.path} render={() => <ReturnProduct />} />
          <Route path={routes.contact.path} render={() => <Contact />} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};
