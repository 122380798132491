import { Api } from "../../api/api";
import { cartLocalstorage } from "../../utils/localstorage";
import * as actions from "./cartActions";
import { getCartObject, getProductIds } from "./cartSelectors";

export const addProductToCart = ({ id, count }) => async (
  dispatch,
  getState
) => {
  await dispatch(actions.addProduct({ id, count }));
  const products = getCartObject(getState());
  cartLocalstorage.set(products);
};

export const addProductFromLocalstorage = () => (dispatch) => {
  const products = cartLocalstorage.get() ?? {};
  dispatch(actions.addLocalstorageProducts(products));
};

export const fetchproductByIds = (ids) => async (dispatch, getState) => {
  try {
    const ids = getProductIds(getState()).join();
    if (!ids) return;
    dispatch(actions.fetchByIds.start());
    const res = await Api.products.fetchByIds(ids);
    dispatch(actions.fetchByIds.success(res.data));
  } catch (e) {
    dispatch(actions.fetchByIds.error(e.message));
  }
};

export const removeProductFromCart = (prodictId) => (dispatch, getState) => {
  dispatch(actions.removeProduct(prodictId));
  const products = getCartObject(getState());
  cartLocalstorage.set(products);
};

export const clearCart = () => (dispatch) => {
  dispatch(actions.clearCart());
  cartLocalstorage.set(null);
};
