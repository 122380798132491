import * as actions from "./productActions";

import { handleActions } from "@letapp/redux-actions";

const defaultState = {
  items: [],
  brands: [],
  isLoading: false,
  isError: false,
  error: null,
  countPages: null,
  last: {
    isLoading: false,
    isError: false,
    error: null,
    items: [],
  },
  discount: {
    isLoading: false,
    isError: false,
    error: null,
    items: [],
  },
  detail: {
    isLoading: false,
    isError: false,
    error: null,
    item: null,
  },
};

export default handleActions(
  {
    //products
    [actions.fetchCategory.start]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [actions.fetchCategory.success]: (state, action) => ({
      ...state,
      isLoading: false,
      items: action.payload.products,
      countPages: action.payload.countPages,
    }),
    [actions.fetchCategory.error]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      error: action.payload,
    }),

    //discount products

    [actions.fetchDiscount.start]: (state) => ({
      ...state,
      discount: {
        ...state.discount,
        isLoading: true,
        error: null,
      },
    }),
    [actions.fetchDiscount.success]: (state, action) => ({
      ...state,
      discount: {
        ...state.discount,
        isLoading: false,
        items: action.payload,
      },
    }),
    [actions.fetchDiscount.error]: (state, action) => ({
      ...state,
      discount: {
        ...state.discount,
        isLoading: false,
        isError: true,
        error: action.payload,
      },
    }),

    //last products

    [actions.fetchLast.start]: (state) => ({
      ...state,
      last: {
        ...state.last,
        isLoading: true,
        error: null,
      },
    }),
    [actions.fetchLast.success]: (state, action) => ({
      ...state,
      last: {
        ...state.last,
        isLoading: false,
        items: action.payload,
      },
    }),
    [actions.fetchLast.error]: (state, action) => ({
      ...state,
      last: {
        ...state.last,
        isLoading: false,
        isError: true,
        error: action.payload,
      },
    }),

    //brands

    [actions.fetchBrand]: (state, action) => ({
      ...state,
      brands: action.payload,
    }),

    //product
    [actions.fetchDetail.start]: (state) => ({
      ...state,
      detail: {
        ...state.detail,
        isLoading: true,
        error: null,
      },
    }),
    [actions.fetchDetail.success]: (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        isLoading: false,
        item: action.payload,
      },
    }),
    [actions.fetchDetail.error]: (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        isLoading: false,
        isError: true,
        error: action.payload,
      },
    }),
  },
  defaultState
);
