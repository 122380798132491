function getItem(key) {
  const item = window.localStorage.getItem(key);
  return JSON.parse(item);
}

function setItem(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export const cartLocalstorage = {
  get: () => getItem("cartAvonShop"),
  set: (data) => setItem("cartAvonShop", data),
};
