import { createAction, createAsyncActions } from "@letapp/redux-actions";

export const addProduct = createAction("cart/PRODUCT_ADD");

export const fetchByIds = createAsyncActions("cart/FETCH_BY_IDS");

export const addLocalstorageProducts = createAction(
  "cart/ADD_LOCALSTORAGE_PRODUCTS"
);

export const removeProduct = createAction("cart/REMOVE_PRODUCT");

export const clearCart = createAction("cart/CLEAR_CART");
