import { Api } from "../../api/api";
import { addProductFromLocalstorage } from "../cart/cartThunk";
import * as actions from "./appActions";

export const appInit = () => async (dispatch) => {
  try {
    dispatch(actions.initializations.start());
    await dispatch(addProductFromLocalstorage());
    const res = await Api.category.fetch();
    await dispatch(
      actions.initializations.success({
        categorys: res.data,
      })
    );
  } catch (e) {
    dispatch(actions.initializations.error(e.message));
  }
};

export const fetchSlider = () => async (dispatch) => {
  try {
    dispatch(actions.fetchSlidser.start());
    const res = await Api.slider.fetch();
    dispatch(actions.fetchSlidser.success(res.data));
  } catch (e) {
    dispatch(actions.fetchSlidser.error(e.message));
  }
};
