export const isProductInCart = (state, productId) =>
  state.cart.items[productId];

export const getProductIds = (state) => Object.keys(state.cart.items);

export const getCartProducts = (state) => Object.values(state.cart.items);

export const getCartObject = (state) => state.cart.items;

export const getCartInfo = (state) =>
  Object.values(state.cart.items).reduce(
    (cartInfo, curent) => {
      cartInfo.total += parseInt(curent.price) * curent.count;
      if (curent.old_price) {
        cartInfo.sale +=
          parseInt(curent.old_price || 0) * curent.count -
          parseInt(curent.price) * curent.count;
      }
      cartInfo.oldTotal +=
        parseInt(curent.old_price || curent.price) * curent.count;
      cartInfo.count += curent.count;
      return cartInfo;
    },
    { total: 0, sale: 0, count: 0, oldTotal: 0 }
  );

export const isCartLoading = (state) => state.cart.isLoading;

export const getOrderProducts = (state) =>
  Object.values(state.cart.items).map((item) => ({
    count: item.count,
    price: item.price,
    product: item.id,
  }));
