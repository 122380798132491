import * as actions from "./cartActions";

import { handleActions } from "@letapp/redux-actions";

const defaultState = {
  items: {},
  isLoading: false,
  isError: false,
  error: null,
};

const addProduct = (items, product) => {
  const itemProduct = items[product.id];
  if (itemProduct?.count === 1 && product.count < 0) {
    return items;
  }
  if (itemProduct) {
    return {
      ...items,
      [product.id]: {
        ...itemProduct,
        count: itemProduct.count + product.count,
      },
    };
  } else {
    return {
      ...items,
      [product.id]: product,
    };
  }
};

const removeProduct = (items, id) => {
  delete items[id];
  return { ...items };
};

export default handleActions(
  {
    //products add
    [actions.addProduct]: (state, action) => ({
      ...state,
      items: addProduct(state.items, action.payload),
    }),

    //products from localstorage
    [actions.addLocalstorageProducts]: (state, action) => ({
      ...state,
      items: action.payload,
    }),

    [actions.fetchByIds.start]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [actions.fetchByIds.success]: (state, action) => ({
      ...state,
      isLoading: false,
      items: action.payload.reduce(
        (items, product) => ({
          ...items,
          [product.id]: { ...product, ...state.items[product.id] },
        }),
        {}
      ),
    }),
    [actions.fetchByIds.error]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      error: action.payload,
    }),

    [actions.removeProduct]: (state, action) => ({
      ...state,
      items: removeProduct(state.items, action.payload),
    }),

    [actions.clearCart]: (state) => ({
      ...state,
      items: {},
    }),
  },
  defaultState
);
