export const routes = {
  home: {
    path: "/",
    name: "Головна",
  },
  category: {
    path: "/category/:slug",
  },

  product: {
    path: "/product/:slug",
  },

  brand: {
    path: "/brand/:slug",
  },

  delivery: {
    path: "/delivery",
    name: "Доставка та оплата",
  },

  return: {
    path: "/return",
    name: "Повернення товару",
  },
  contact: {
    path: "/contact",
    name: "Контакти",
  },

  cart: {
    path: "/cart",
    name: "Кошик",
  },

  order: {
    path: "/order",
  },
};
