import React from "react";
import s from "./other.module.scss";

export const Spiner = () => {
  return (
    <div className={`spinner-border text-danger ${s.spiner}`} role="status">
      <span className="visually-hidden"></span>
    </div>
  );
};
