import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.shop-avon.com.ua/api/",
  // baseURL: "http://localhost:8000/api/",
});

export const Api = {
  category: {
    fetch: () => instance.get("categorys/"),
  },
  brand: {
    fetch: (categorySlug) => instance.get(`brands/${categorySlug}`),
  },
  slider: {
    fetch: () => instance.get("sliders/"),
  },

  products: {
    fetchLast: () => instance.get("last-product/"),
    fetchDiscount: () => instance.get("best-discount/"),
    fetchCategory: (slug, searchParams) =>
      instance.get(`category/${slug}?${searchParams}`),

    fetchBySlug: (slug) => instance.get(`product/${slug}`),

    fetchBrand: (slug, searchParams) =>
      instance.get(`brand/${slug}?${searchParams}`),

    fetchByIds: (ids) => instance.get(`products?id=${ids}`),
  },
  order: {
    create: ({ castomer, products, delivery, total_price, payment }) =>
      instance.post("order/", {
        castomer,
        products,
        delivery,
        total_price,
        payment,
      }),
  },
};
