import * as actions from "./appActions";

import { handleActions } from "@letapp/redux-actions";

const defaultState = {
  categorys: [],
  isLoading: false,
  isError: false,
  error: null,
  slider: {
    isLoading: false,
    isError: false,
    error: null,
    items: [],
  },
};

export default handleActions(
  {
    [actions.initializations.start]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [actions.initializations.success]: (state, action) => ({
      ...state,
      isLoading: false,
      categorys: action.payload.categorys,
    }),
    [actions.initializations.error]: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      error: action.payload,
    }),

    //slider

    [actions.fetchSlidser.start]: (state) => ({
      ...state,
      slider: {
        ...state.slider,
        isLoading: true,
        error: null,
      },
    }),
    [actions.fetchSlidser.success]: (state, action) => ({
      ...state,
      slider: {
        ...state.slider,
        isLoading: false,
        items: action.payload,
      },
    }),
    [actions.fetchSlidser.error]: (state, action) => ({
      ...state,
      slider: {
        ...state.slider,
        isLoading: false,
        isError: true,
        error: action.payload,
      },
    }),
  },
  defaultState
);
