import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import "./App.scss";
import { Spiner } from "./components/Other/Spiner";
import { isAppLoading } from "./modules/app/appSelectors";
import { appInit } from "./modules/app/appThunk";
import { AppRouter } from "./routes/AppRouter";

const AppView = ({ isLoading, appInit }) => {
  React.useEffect(() => {
    appInit();
  }, [appInit]);

  if (isLoading) return <Spiner />;
  return (
    <>
      <Helmet />
      <AppRouter />
    </>
  );
};

export const App = connect(
  (state) => ({
    isLoading: isAppLoading(state),
  }),
  { appInit }
)(AppView);
